import { Map, Set, OrderedSet } from 'immutable';
import debounce from 'lodash.debounce';
import moment from 'moment-timezone';

import {
  Filters,
  FilterPeriod,
  FilterOrderMethod,
  FilterPaymentMethod,
} from 'containers/reports/types';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { ClientSource, OrderSource } from 'sources';
import { downloadFile } from 'shared/utils/SharedUtils.js';

import { hasFilterDrawerDefaults } from './utils';
import OrderReportActions from './Actions';

class OrderReportStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();

    this.debouncedListOrders = debounce(() => {
      this.page = 1;
      this.list();
      this.fetchStatistics();
    }, 600);

    this.bindListeners({
      applyFilters: OrderReportActions.applyFilters,
      clearFilters: OrderReportActions.clearFilters,
      clientListError: OrderReportActions.clientListError,
      clientListSuccess: OrderReportActions.clientListSuccess,
      closeFilterDrawer: OrderReportActions.closeFilterDrawer,
      downloadList: OrderReportActions.downloadList,
      downloadListSuccess: OrderReportActions.downloadListSuccess,
      downloadListError: OrderReportActions.downloadListError,
      fetchStatistics: OrderReportActions.fetchStatistics,
      fetchStatisticsError: OrderReportActions.fetchStatisticsError,
      fetchStatisticsSuccess: OrderReportActions.fetchStatisticsSuccess,
      list: OrderReportActions.list,
      listError: OrderReportActions.listError,
      listSuccess: OrderReportActions.listSuccess,
      mounted: OrderReportActions.mounted,
      openFilterDrawer: OrderReportActions.openFilterDrawer,
      removeFilter: OrderReportActions.removeFilter,
      selectDay: OrderReportActions.selectDay,
      selectPage: OrderReportActions.selectPage,
      updateFilter: OrderReportActions.updateFilter,
      openColumnDrawer: OrderReportActions.openColumnDrawer,
      closeColumnDrawer: OrderReportActions.closeColumnDrawer,
      changeColumnVisibility: OrderReportActions.changeColumnVisibility,
    });
  }

  reset() {
    this.isMobile = false;
    this.isFilterDrawerOpen = false;
    this.isColumnDrawerOpen = false;
    this.isLoadingOrder = false;
    this.isLoadingStatistics = false;
    this.isLoadingClients = false;
    this.statistics = Map();
    this.defaultFilters = Map({
      search: '',
      orderMethod: FilterOrderMethod.ALL,
      period: {
        value: FilterPeriod.today,
        from: moment().startOf('day'),
        to: moment().endOf('day'),
      },
      paymentMethod: FilterPaymentMethod.ALL,
    });
    this.activeFilters = Map({
      period: this.defaultFilters.get(Filters.PERIOD),
    });
    this.drawerFilters = Map();
    this.isFilterDrawerChanged = false;
    this.columns = Map({
      orderNumber: true,
      buyer: true,
      channel: true,
      amount: true,
      method: true,
    });
    this.page = 1;
    this.perPage = 10;
    this.totalCount = 0;
    this.orderIds = OrderedSet();
  }

  mounted(isMobile) {
    this.reset();
    this.isMobile = isMobile;
    this.isLoadingStatistics = true; // Changing here for showing header during on filter change
    this.list();
    this.fetchStatistics();
  }

  openFilterDrawer() {
    this.isFilterDrawerOpen = true;
    this.isFilterDrawerChanged = false;
    this.drawerFilters = Map(this.activeFilters);
  }

  closeFilterDrawer() {
    this.isFilterDrawerOpen = false;
    this.drawerFilters = Map();
  }

  getParams() {
    const params = {
      status: 'completed',
    };

    if (this.activeFilters.get('paymentMethod') !== FilterPaymentMethod.ALL) {
      params.payment_method = this.activeFilters.get('paymentMethod');
    }
    if (this.activeFilters.get(Filters.PERIOD)) {
      params.ordered_at_min = this.activeFilters
        .get(Filters.PERIOD)
        .from.toISOString();
      params.ordered_at_max = this.activeFilters
        .get(Filters.PERIOD)
        .to.toISOString();
    }
    if (this.activeFilters.get('search')) {
      params.search = this.activeFilters.get('search');
    }
    if (this.activeFilters.get('orderMethod') !== FilterOrderMethod.ALL) {
      params.order_method = this.activeFilters.get('orderMethod');
    }

    return params;
  }

  list() {
    this.isLoadingOrder = true;

    const params = {
      page: this.page,
      per_page: this.perPage,
      fields: ['order_items', 'coupon'],
      ...this.getParams(),
    };

    OrderSource.list({
      params,
      success: OrderReportActions.listSuccess,
      error: OrderReportActions.listError,
    });
  }

  listSuccess({ orders, page, perPage, totalCount }) {
    const newIds = orders.map(el => el.id).toOrderedSet();

    if (this.isMobile) {
      this.orderIds = page === 1 ? newIds : this.orderIds.union(newIds);
    } else {
      this.orderIds = newIds;
    }
    const buyerIds = orders
      .reduce(
        (set, order) =>
          order.get('order_items').size
            ? set.add(order.getIn(['order_items', 0, 'buyer_id']))
            : set,
        Set()
      )
      .toJS();
    if (buyerIds.length) {
      this.isLoadingClients = true;
      ClientSource.list({
        params: {
          ids: buyerIds,
          per_page: 50,
        },
        success: OrderReportActions.clientListSuccess,
        error: OrderReportActions.clientListError,
      });
    }

    this.page = this.isMobile ? page + 1 : page;
    this.perPage = perPage;
    this.totalCount = totalCount;

    this.isLoadingOrder = false;
  }

  listError() {
    this.isLoadingOrder = false;
  }

  clientListSuccess() {
    this.isLoadingClients = false;
  }

  clientListError() {
    this.isLoadingClients = false;
  }

  fetchStatistics() {
    const params = this.getParams();

    uhApiClient.get({
      url: 'orders/statistics',
      data: params,
      success: OrderReportActions.fetchStatisticsSuccess,
      error: OrderReportActions.fetchStatisticsError,
    });
  }

  fetchStatisticsSuccess(data) {
    this.statistics = Map(data);
    this.isLoadingStatistics = false;
  }

  fetchStatisticsError(...args) {
    this.isLoadingStatistics = false;
    this.notifyError('error fetching order statistics', args);
  }

  applyFilters() {
    this.activeFilters = Map(this.drawerFilters);
    this.page = 1;
    this.list();
    this.fetchStatistics();
    this.isFilterDrawerOpen = false;
    this.isFilterDrawerChanged = false;
  }

  clearFilters() {
    if (this.isFilterDrawerOpen) {
      this.drawerFilters = Map(this.activeFilters)
        .delete(Filters.ORDER_METHOD)
        .delete(Filters.PAYMENT_METHOD);
      this.isFilterDrawerChanged = !hasFilterDrawerDefaults(this.activeFilters);
    } else {
      this.activeFilters = Map({
        period: this.defaultFilters.get(Filters.PERIOD),
      });
      this.page = 1;
      this.list();
      this.fetchStatistics();
    }
  }

  updateFilter([key, value]) {
    let newFilters = this.isFilterDrawerOpen
      ? this.drawerFilters
      : this.activeFilters;

    newFilters = newFilters.set(key, value);

    if (key === Filters.ORDER_METHOD && value === FilterOrderMethod.ALL) {
      newFilters = newFilters.delete(key);
    }
    if (key === Filters.PAYMENT_METHOD && value === FilterPaymentMethod.ALL) {
      newFilters = newFilters.delete(key);
    }

    if (this.isFilterDrawerOpen) {
      this.drawerFilters = newFilters;
      this.isFilterDrawerChanged = true;
    } else {
      this.activeFilters = newFilters;
      if (key === Filters.SEARCH) {
        this.debouncedListOrders();
      } else {
        this.page = 1;
        this.list();
        this.fetchStatistics();
      }
    }
  }

  removeFilter(filterName) {
    if (filterName === Filters.PERIOD) {
      this.activeFilters = this.activeFilters.set(filterName, {
        value: FilterPeriod.today,
        from: moment().startOf('day'),
        to: moment().endOf('day'),
      });
    } else {
      this.activeFilters = this.activeFilters.delete(filterName);
    }
    this.page = 1;
    this.list();
    this.fetchStatistics();
  }

  selectPage([page, perPage]) {
    this.page = page;
    this.perPage = perPage;
    this.list();
  }

  selectDay(value) {
    this.page = 1;

    if (value === 1 || value === -1) {
      this.activeFilters = this.activeFilters.set(Filters.PERIOD, {
        value: FilterPeriod.custom,
        from: this.activeFilters.get(Filters.PERIOD).from.add(value, 'days'),
        to: this.activeFilters.get(Filters.PERIOD).to.add(value, 'days'),
      });
    } else {
      const date = value || moment();

      this.activeFilters = this.activeFilters.set(Filters.PERIOD, {
        value: FilterPeriod.custom,
        from: date.clone().local().startOf('day'),
        to: date.clone().local().endOf('day'),
      });
    }

    this.list();
    this.fetchStatistics();
  }

  openColumnDrawer() {
    this.isColumnDrawerOpen = true;
  }

  closeColumnDrawer() {
    this.isColumnDrawerOpen = false;
  }

  changeColumnVisibility([key, value]) {
    this.columns = this.columns.set(key, value);
  }

  downloadList() {
    this.isOrderListDownloading = true;
    OrderSource.downloadCsv({
      params: this.getParams(),
      success: OrderReportActions.downloadListSuccess,
      error: OrderReportActions.downloadListError,
    });
  }

  downloadListSuccess(data) {
    downloadFile({
      data,
      fileName: 'order_list.csv',
    });
    this.isOrderListDownloading = false;
  }

  downloadListError() {
    this.isOrderListDownloading = false;
  }
}

export default alt.createStore(OrderReportStore, 'OrderReportStore');
