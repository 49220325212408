import { Map } from 'immutable';

import { CustomBusinessEmailsActions } from 'sources';

// eslint-disable-next-line import/no-cycle
import { UpperHandStore } from 'dataStores';

class CustomBusinessEmailsDataStore extends UpperHandStore {
  constructor() {
    super();

    this.custom_emails = Map();

    this.bindListeners({
      createSuccess: CustomBusinessEmailsActions.createSuccess,
      createError: CustomBusinessEmailsActions.createError,
      fetchSuccess: CustomBusinessEmailsActions.fetchSuccess,
      fetchError: CustomBusinessEmailsActions.fetchError,
      updateSuccess: CustomBusinessEmailsActions.updateSuccess,
      updateError: CustomBusinessEmailsActions.updateError,
    });
  }

  createSuccess(customEmail) {
    const { email_type: emailType } = customEmail;

    this.custom_emails = this.custom_emails.set(emailType, customEmail);
  }

  createError(...args) {
    this.notifyError('Error creating custom email', args);
  }

  fetchSuccess(customEmail) {
    const { email_type: emailType } = customEmail;

    this.custom_emails = this.custom_emails.set(emailType, customEmail);
  }

  fetchError(...args) {
    this.notifyError('Error fetching custom email', args);
  }

  updateSuccess(customEmail) {
    const { email_type: emailType } = customEmail;

    this.custom_emails = this.custom_emails.set(emailType, customEmail);
  }

  updateError(...args) {
    this.notifyError('Error updating custom email', args);
  }
}

export default alt.createStore(
  CustomBusinessEmailsDataStore,
  'CustomBusinessEmailsDataStore'
);
