import { Map, Set } from 'immutable';
import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';

import { StaffSource, SessionSource, ClientSource, EventSource } from 'sources';

import Order from 'event_mgmt/shared/records/Order.jsx';
import OrderItem from 'shared/records/OrderItem';

import { isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { enabledCustomerFeatures } from 'shared/utils/CustomerUtils';

import { SessionDataStore } from 'dataStores';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';

const CHECKOUT_STEPS = {
  REVIEW: 'review',
  PAYMENT_SELECT: 'paymentSelect',
  PROCESSING: 'processing',
  SUMMARY: 'summary',
};

const url = id => {
  if (id) {
    return `/order_items/${id}`;
  }
  return '/order_items';
};

class CartStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.isLoadingCart = !!isLoggedIn();

    this.bindListeners({
      handleFetchCart: CartActions.fetch,
      handleFetchCartSuccess: CartActions.fetchSuccess,
      handleFetchCartError: CartActions.fetchError,

      handleUpdate: CartActions.update,
      handleUpdateSuccess: CartActions.updateSuccess,

      addCreditPassPackageToCart: CartActions.creditPassPackageAdded,
      addRegistrationPackageToCart: CartActions.registrationPackageAdded,
      addSubscriptionPackageToCart: CartActions.subscriptionPackageAdded,
      handleAddSuccess: CartActions.addSuccess,

      handleRemove: CartActions.remove,
      handleRemoveSuccess: CartActions.removeSuccess,

      handleError: [CartActions.updateError, CartActions.removeError],
      handleAddError: CartActions.addError,

      handleUpdateCheckoutStep: CartActions.updateCheckoutStep,

      applyCoupon: CartActions.COUPON_APPLIED,
      applyCouponSuccess: CartActions.APPLY_COUPON_SUCCESS,
      applyCouponError: CartActions.APPLY_COUPON_ERROR,

      updateAccountCredits: CartActions.UPDATE_ACCOUNT_CREDITS,
      applyAccountCredits: CartActions.APPLY_ACCOUNT_CREDITS,
      applyAccountCreditsSuccess: CartActions.APPLY_ACCOUNT_CREDITS_SUCCESS,
      applyAccountCreditsError: CartActions.APPLY_ACCOUNT_CREDITS_ERROR,

      reset: CartActions.resetAfterSuccess,

      handleFetchEventsSuccess: CartActions.fetchEventsSuccess,
      handleFetchEventsError: CartActions.fetchEventsError,

      handleFetchSessionsSuccess: CartActions.fetchSessionsSuccess,
      handleFetchSessionsError: CartActions.fetchSessionsError,

      handleFetchStaffSuccess: CartActions.fetchStaffSuccess,
      handleFetchStaffError: CartActions.fetchStaffError,

      toggleCheckoutStep: CartActions.toggleCheckoutStep,
      resetOnUnmount: CartActions.resetOnUnmount,

      updateInsurance: CartActions.updateInsurance,
      applyInsurance: CartActions.applyInsurance,
      applyInsuranceSuccess: CartActions.applyInsuranceSuccess,
      applyInsuranceError: CartActions.applyInsuranceError,
      clearInsurance: CartActions.clearInsurance,
      emptyOfferError: CartActions.emptyOfferError,
    });
  }

  reset() {
    this.cart = new Order();
    this.cartSize = -1;
    this.previousCart = null;
    this.disableContinueButton = false;

    this.redirectOnSuccess = true;
    this.isNavigatingToCart = false;
    this.isLoadingCart = false;
    this.isUpdatingCart = false;
    this.isOrderItem = false;
    this.showContinueToCheckout = true;

    this.checkoutStep = CHECKOUT_STEPS.REVIEW;

    this.isLoadingCoupon = false;
    this.insurance = Map({
      'gap-medical': 0,
      'registration-cancellation': 0,
    });
    this.insuranceMetadata = Map({
      'gap-medical': [],
      'registration-cancellation': [],
    });
    this.emptyOffersTypes = Set();
  }

  resetOnUnmount() {
    this.redirectOnSuccess = true;
    this.checkoutStep = CHECKOUT_STEPS.REVIEW;
    this.showContinueToCheckout = true;
  }

  toggleCheckoutStep() {
    this.showContinueToCheckout = !this.showContinueToCheckout;
  }

  updateInsurance({ type, data }) {
    const insuranceData = Array.isArray(data) ? data : [data];

    const insuranceAmount = insuranceData.reduce((acc, item) => {
      const total = item.selectedState === 'ACCEPTED' ? item.quote.total : 0;

      return acc + total;
    }, 0);

    if (insuranceAmount > 0) {
      const selectedItems = insuranceData.filter(
        item => item.selectedState === 'ACCEPTED'
      );
      const quoteIds = selectedItems.map(item => item.quote.quote_id);

      this.insuranceMetadata = this.insuranceMetadata.set(type, Set(quoteIds));
    } else {
      this.insuranceMetadata = this.insuranceMetadata.set(type, Set());
    }

    this.insurance = this.insurance.set(type, insuranceAmount);

    const totalInsurance =
      this.insurance.get('gap-medical') +
      this.insurance.get('registration-cancellation');

    this.cart = this.cart.set('insurance_amount', totalInsurance);
    this.cart = this.cart.set('insurance_metadata', this.insuranceMetadata);

    this.validateInsurance();
  }

  applyInsurance(step) {
    uhApiClient.patch({
      url: `orders/${this.cart.id}`,
      data: JSON.stringify({
        attributes: {
          coupon_code: this.cart.coupon?.code,
          insurance_amount: this.cart.insurance_amount,
          insurance_metadata: this.cart.insurance_metadata.toJS(),
        },
        fields: [
          'insurance_amount',
          'insurance_metadata',
          'coupon',
          'order_items',
        ],
      }),
      success: {
        action: CartActions.applyInsuranceSuccess,
        args: [step],
      },
      error: CartActions.applyInsuranceError,
    });
  }

  applyInsuranceSuccess([order, step]) {
    this.cart = new Order(order);

    if (step) {
      this.checkoutStep = step;
      this.disableContinueButton = true;
    }
  }

  applyInsuranceError(...args) {
    this.notifyError('Error while applying insurance', args);
  }

  clearInsurance(step) {
    this.insurance = Map({
      'gap-medical': 0,
      'registration-cancellation': 0,
    });
    this.insuranceMetadata = Map({
      'gap-medical': [],
      'registration-cancellation': [],
    });
    this.cart = this.cart.set('insurance_amount', 0);
    this.cart = this.cart.set('insurance_metadata', this.insuranceMetadata);

    this.applyInsurance(step);
  }

  validateInsurance = async () => {
    const medicalInsurance = document.querySelector('.insurance-gap-medical');
    const registrationInsurance = document.querySelector(
      '.insurance-registration-cancellation'
    );

    const medicalValidation = await medicalInsurance?.validate();
    const cancellationValidation = await registrationInsurance?.validate();

    let isValid = medicalValidation?.isValid && cancellationValidation?.isValid;

    if (this.emptyOffersTypes.has('gap-medical')) {
      isValid = cancellationValidation?.isValid;
    }

    if (this.emptyOffersTypes.has('registration-cancellation')) {
      isValid = medicalValidation?.isValid;
    }

    if (
      this.emptyOffersTypes.has('gap-medical') &&
      this.emptyOffersTypes.has('registration-cancellation')
    ) {
      isValid = true;
    }

    this.setState({
      disableContinueButton: !isValid,
    });

    return isValid;
  };

  emptyOfferError = async ([_, type]) => {
    this.emptyOffersTypes = this.emptyOffersTypes.add(type);
    this.validateInsurance();
  };

  handleFetchCart({ shouldSpin = true, shouldFetchAdditionalInfo = true }) {
    this.isLoadingCart = shouldSpin;
    return uhApiClient.get({
      url: '/cart',
      data: { fields: ['coupon', 'order_items'] },
      success: {
        action: CartActions.fetchSuccess,
        args: [shouldFetchAdditionalInfo],
      },
      error: CartActions.fetchError,
    });
  }

  listInsuranceAdditionalInfo() {
    const clientIds = this.cart.order_items.reduce((acc, item) => {
      if (item.isTeamItem() || item.isFixedScheduleItem()) {
        return acc.concat(item.orderable.client_ids.toJS());
      }

      return acc;
    }, []);

    this.isLoadingCart = true;
    ClientSource.list({
      params: {
        ids: clientIds,
      },
      success: () => {
        this.isLoadingCart = false;
      },
      error: () => {
        this.isLoadingCart = false;
      },
    });
  }

  handleFetchCartSuccess([data, shouldFetchAdditionalInfo]) {
    this.cart = new Order(data);
    this.cartSize = this.cart.order_items.size;

    if (enabledCustomerFeatures(['insurance'])) {
      this.disableContinueButton = true;
    }

    if (this.cartSize) {
      ClientSource.fetch({
        id: this.cart.get('order_items').first().get('buyer_id'),
        params: {
          fields: ['total_account_credit'],
        },
        success: () => null,
      });
    }

    this.listInsuranceAdditionalInfo();

    const eventIds = this.cart.order_items
      .filter(item => item.isEventItem())
      .map(item => item.orderable?.event_id);
    const fsEventIds = this.cart.order_items
      .filter(item => item.isEventItem() && item.isFixedScheduleItem())
      .map(item => item.orderable?.event_id);
    const teamEventIds = this.cart.order_items
      .filter(item => item.isEventItem() && item.isTeamItem())
      .map(item => item.orderable?.event_id);
    const obEventIds = this.cart.order_items
      .filter(item => item.isEventItem() && item.isOpenBookingItem())
      .map(item => item.orderable?.event_id);

    if (obEventIds.size > 0 || fsEventIds.size > 0 || teamEventIds.size > 0) {
      this.handleFetchEvents({
        eventIds: [
          ...obEventIds.toJS(),
          ...fsEventIds.toJS(),
          ...teamEventIds.toJS(),
        ],
      });
    }

    if (eventIds.size > 0 && shouldFetchAdditionalInfo) {
      this.handleFetchStaff({ eventIds });
      this.handleFetchSessions({ eventIds: fsEventIds.toJS() });
    } else {
      this.isLoadingCart = false;
    }
  }

  handleFetchCartError(...args) {
    this.isLoadingCart = false;
    this.notifyError('error while fetching cart', args);
  }

  handleFetchEvents({ eventIds = [], page = 1 }) {
    if (eventIds.length === 0) return;

    this.isLoadingCart = true;
    EventSource.list({
      params: {
        page,
        per_page: 50,
        ids: eventIds,
      },
      success: {
        action: CartActions.fetchEventsSuccess,
        args: [eventIds],
      },
      error: CartActions.fetchEventsError,
    });
  }

  handleFetchEventsSuccess([{ page, perPage, totalCount }, eventIds]) {
    if (page * perPage < totalCount) {
      this.handleFetchEvents({ eventIds, page: page + 1 });
    } else {
      this.isLoadingCart = false;
    }
  }

  handleFetchEventsError() {
    this.isLoadingCart = false;
  }

  handleFetchSessions({ eventIds = [], page = 1 }) {
    if (eventIds.length === 0) return;

    this.isLoadingCart = true;
    SessionSource.list({
      params: {
        page,
        per_page: 50,
        event_ids: eventIds,
      },
      success: {
        action: CartActions.fetchSessionsSuccess,
        args: [eventIds],
      },
      error: CartActions.fetchSessionsError,
    });
  }

  handleFetchSessionsSuccess([{ page, perPage, totalCount }, eventIds]) {
    if (page * perPage < totalCount) {
      this.handleFetchSessions({ eventIds, page: page + 1 });
    } else {
      this.isLoadingCart = false;
    }
  }

  handleFetchSessionsError() {
    this.isLoadingCart = false;
  }

  handleFetchStaff({ eventIds, page = 1 }) {
    this.isLoadingCart = true;
    StaffSource.list({
      params: { page, per_page: 50, event_ids: eventIds.toJS() },
      success: {
        action: CartActions.fetchStaffSuccess,
        args: [eventIds],
      },
      error: CartActions.fetchStaffError,
    });
  }

  handleFetchStaffSuccess([{ page, perPage, totalCount }, eventIds]) {
    if (page * perPage < totalCount) {
      this.handleFetchStaff({ eventIds, page: page + 1 });
    } else {
      this.isLoadingCart = false;
    }
  }

  handleFetchStaffError() {
    this.isLoadingCart = false;
  }

  handleUpdateCheckoutStep(step) {
    const insuranceAmount = this.cart.insurance_amount;

    // apply insurance when Continue to payment btn is clicked
    if (insuranceAmount > 0 && step === CHECKOUT_STEPS.PAYMENT_SELECT) {
      this.applyInsurance(step);
      return;
    }

    this.checkoutStep = step;
  }

  addRegistrationPackageToCart([
    registrationPackage,
    opts = { redirect: false, customSuccessAction: null },
  ]) {
    this.redirectOnSuccess = !!opts.redirect;
    this.addOrderableToCart(
      registrationPackage,
      'registration_package',
      opts.customSuccessAction
    );
  }

  addSubscriptionPackageToCart(subscriptionPackage) {
    this.addOrderableToCart(
      subscriptionPackage,
      'membership_subscription_package'
    );
  }

  addCreditPassPackageToCart(creditPassPackage) {
    this.addOrderableToCart(creditPassPackage, 'credit_pass_package');
  }

  getOrderParams(item) {
    this.waitFor(EventStore);
    this.waitFor(SessionDataStore);
    const { customerEvent } = EventStore.getState();
    const { pagination } = SessionDataStore.getState();
    const orderParams = item.toServer();
    const hasTeamEvent = customerEvent.isTeamEvent();
    if (hasTeamEvent) {
      const sessionsCount = pagination.get('totalCount');
      orderParams.registration_package_attributes.quantity =
        sessionsCount > 0 ? sessionsCount : 1;
    }
    return orderParams;
  }

  addOrderableToCart(orderable, orderableType, customSuccessAction) {
    this.handleFetchCart({});

    let newItem = new OrderItem({
      order_id: this.cart.id,
      orderable,
      orderable_type: orderableType,
      quantity: 1,
    });

    if (newItem.isCreditPassItem()) {
      newItem = newItem.set('quantity', newItem.orderable.client_ids.size);
    }

    if (!customSuccessAction) {
      this.isUpdatingCart = true;
      this.isOrderItem = true;
      this.isLoadingCart = true;
    }

    return uhApiClient.post({
      url: url(),
      data: JSON.stringify({
        attributes: this.getOrderParams(newItem),
      }),
      success: customSuccessAction || CartActions.addSuccess,
      error: CartActions.addError,
    });
  }

  handleAddSuccess(_orderItem) {
    this.isLoadingCart = false;
    this.isOrderItem = false;
    this.previousCart = this.cart;
    this.handleFetchCart({ shouldSpin: false });

    this.isUpdatingCart = false;

    if (this.redirectOnSuccess) {
      this.navigateToCart();
    } else {
      MessageWindowActions.addMessage.defer('Added to cart.');
    }
  }

  handleUpdate([id, newOrderable, opts = { redirect: false }]) {
    this.isUpdatingCart = true;
    this.redirectOnSuccess = !!opts.redirect;

    const index = this.cart.order_items.findIndex(oi => oi.id === id);

    let updatedItem = this.cart
      .getIn(['order_items', index])
      .set('orderable', newOrderable);

    if (updatedItem.isCreditPassItem()) {
      updatedItem = updatedItem.set(
        'quantity',
        updatedItem.orderable.client_ids.size
      );
    }

    this.previousCart = this.cart;
    this.cart = this.cart.setIn(['order_items', index], updatedItem);
    this.cartSize = this.cart.order_items.size;

    return uhApiClient.put({
      url: url(updatedItem.id),
      data: JSON.stringify({
        attributes: this.getOrderParams(updatedItem),
      }),
      success: CartActions.updateSuccess,
      error: CartActions.updateError,
    });
  }

  handleUpdateSuccess(item) {
    const updatedItem = new OrderItem(item);
    const index = this.cart.order_items.findIndex(
      oi => oi.id === updatedItem.id
    );

    this.cart = this.cart.setIn(['order_items', index], updatedItem);
    this.cartSize = this.cart.order_items.size;

    this.isUpdatingCart = false;

    if (this.redirectOnSuccess) {
      this.navigateToCart();
    } else {
      MessageWindowActions.addMessage.defer('Cart updated.');
    }
  }

  handleRemove(id) {
    this.isUpdatingCart = true;
    this.previousCart = this.cart;
    this.cart = this.cart.removeItem(id);
    this.cartSize = this.cart.order_items.size;

    return uhApiClient.delete({
      url: url(id),
      success: CartActions.removeSuccess,
      error: CartActions.removeError,
    });
  }

  handleRemoveSuccess(_data) {
    this.handleFetchCart({
      shouldSpin: false,
      shouldFetchAdditionalInfo: false,
    });
    this.isUpdatingCart = false;
  }

  handleError(error) {
    this.isLoadingCart = false;
    this.isOrderItem = false;
    this.cart = this.previousCart;
    this.cartSize = this.cart && this.cart.order_items.size;
    this.isUpdatingCart = false;
    MessageWindowActions.addMessage.defer(error?.httpMessage);
  }

  handleAddError(error) {
    this.cart = this.previousCart;
    this.cartSize = this.cart && this.cart.order_items.size;
    this.isUpdatingCart = false;
    MessageWindowActions.addMessage.defer(error?.httpMessage);
  }

  navigateToCart() {
    this.isNavigatingToCart = true;
    window.location.href = customerScopedRoute('cart');
  }

  applyCoupon({ code }) {
    this.isLoadingCoupon = true;

    return uhApiClient.patch({
      url: `orders/${this.cart.id}`,
      data: JSON.stringify({
        attributes: { coupon_code: code },
        fields: ['coupon', 'order_items'],
      }),
      success: CartActions.applyCouponSuccess,
      error: CartActions.applyCouponError,
    });
  }

  applyCouponSuccess(data) {
    this.isLoadingCoupon = false;
    this.handleFetchCartSuccess([data]);
  }

  applyCouponError(...args) {
    this.isLoadingCoupon = false;
    this.notifyError('error while applying coupon', args);
  }

  updateAccountCredits({ item, creditsAmount }) {
    const index = this.cart.order_items.findIndex(oi => oi.id === item.id);
    const updatedItem = item
      .merge({ account_credit_amount: creditsAmount })
      .validate();

    this.cart = this.cart.setIn(['order_items', index], updatedItem);
  }

  // eslint-disable-next-line class-methods-use-this
  applyAccountCredits({ item }) {
    const attributes = {
      ...item.toServer(),
      orderable_id: item.isRetailItem()
        ? item.getIn(['orderable', 'id'])
        : null,
    };

    uhApiClient.put({
      url: `order_items/${item.id}`,
      data: JSON.stringify({
        attributes,
        fields: ['account_credit_amount'],
      }),
      success: CartActions.applyAccountCreditsSuccess,
      error: CartActions.applyAccountCreditsError,
    });
  }

  applyAccountCreditsSuccess() {
    this.handleFetchCart({
      shouldSpin: false,
      shouldFetchAdditionalInfo: false,
    });
  }

  applyAccountCreditsError(...args) {
    this.notifyError('Error while applying account credits', args);
  }
}

export default alt.createStore(CartStore, 'CartStore');
