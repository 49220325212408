import moment from 'moment-timezone';
import { Record } from 'immutable';
import { merge } from 'shared/utils/ObjectUtils.jsx';

class CustomBusinessEmail extends Record({
  id: null,
  email_type: null,
  subject: null,
  body: null,
  created_at: null,
  updated_at: null,
}) {
  constructor(obj = {}) {
    super(
      merge(obj, {
        created_at: obj.created_at ? moment(obj.created_at) : null,
        updated_at: obj.updated_at ? moment(obj.updated_at) : null,
      })
    );
  }
}

export default CustomBusinessEmail;
