/* eslint-disable react/require-default-props */
import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  GapMedicalComponent,
  RegistrationCancellationComponent,
  MultiOfferWrapperElementComponent,
} from '@vertical-insure/web-components/react';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { enabledCustomerFeatures } from 'shared/utils/CustomerUtils';

import CustomerTeam from 'team_mgmt/shared/records/CustomerTeam.jsx';

import { ClientDataStore, EventDataStore, SessionDataStore } from 'dataStores';

const INSURANCE_CLIENT_ID = window.verticalInsuranceKey;

function InsuranceItem({
  orderable,
  clients,
  events,
  sessions,
  total,
  type = 'gap-medical',
}) {
  const clientIds = orderable.get('client_ids');
  const eventId = orderable.get('event_id');
  const event = events.get(eventId);

  if (!event) return null;

  const tentativeDetails = orderable.get('tentative_details');
  const isFixedSchedule = event?.isFixedSchedule();
  const isSSP = event?.isSSPTurnedOn();
  const schedule = event?.get('schedules').first();
  const location = schedule?.get('location');

  const getCoverageDates = () => {
    // fixed event with no SSP
    if (isFixedSchedule && !isSSP) {
      const startDate = schedule.getIn(['availability_schedule', 'start_date']);
      const endDate = schedule.getIn(['availability_schedule', 'end_date']);

      return {
        startDate,
        endDate,
        isAfterToday: moment(startDate).isAfter(),
      };
    }

    // fixed event with SSP
    if (isFixedSchedule && isSSP) {
      const sortedTentativeDetails = tentativeDetails.sort(
        (a, b) =>
          new Date(sessions.get(a.session_id).starts_at) -
          new Date(sessions.get(b.session_id).starts_at)
      );
      const firstSessionId = sortedTentativeDetails.first().session_id;
      const lastSessionId = sortedTentativeDetails.last().session_id;
      const firstSession = sessions.get(firstSessionId);
      const lastSession = sessions.get(lastSessionId);

      return {
        startDate: firstSession.starts_at.format('YYYY-MM-DD'),
        endDate: lastSession.starts_at.format('YYYY-MM-DD'),
        isAfterToday: firstSession.starts_at.isAfter(),
      };
    }

    const team = new CustomerTeam({ seasons: event.get('seasons').toJS() });

    return {
      startDate: moment(team.dateRange.startDate).format('YYYY-MM-DD'),
      endDate: moment(team.dateRange.endDate).format('YYYY-MM-DD'),
      isAfterToday: moment(team.dateRange.startDate).isAfter(),
    };
  };

  const coverageDates = getCoverageDates();

  // if event or session start date is in the past, do not show insurance.
  // This will fix offerStateChange being called only if insurance declined first.
  if (!coverageDates.isAfterToday) return null;

  return (
    <>
      {clientIds.map(clientId => {
        const client = clients.get(clientId);

        if (!client) return null;

        const managingClient = clients.get(
          client.get('managing_customer_user_id')
        );
        const customer = managingClient || client;
        const participant = client || managingClient;
        const locationAddress = location?.get('address');
        const customerAddress = customer?.get('address') || locationAddress;
        const participantAddress =
          participant?.get('address') || locationAddress;
        const coverageType = event.get('sport_type');
        const coverageDescription = event.get('title', '');

        if (type === 'registration-cancellation') {
          return (
            <RegistrationCancellationComponent
              key={`${type}-${clientId}`}
              insurableAmount={total}
              eventStartDate={coverageDates.startDate}
              eventEndDate={coverageDates.endDate}
              participantFirstName={participant?.get('first_name')}
              participantLastName={participant?.get('last_name')}
              participantBirthDate={participant?.get('date_of_birth')}
              coverageContextDescription={coverageDescription}
              customerFirstName={customer?.get('first_name')}
              customerLastName={customer?.get('last_name')}
              customerEmailAddress={customer?.get('email')}
              customerState={customerAddress?.get('state')}
              customerPostalCode={customerAddress?.get('postal_code')}
              customerCity={customerAddress?.get('city')}
              customerStreetAddress={customerAddress?.streetAddress}
            />
          );
        }

        return (
          <GapMedicalComponent
            key={`${type}-${clientId}`}
            coverageStartDate={coverageDates.startDate}
            coverageEndDate={coverageDates.endDate}
            coverageType={coverageType}
            coverageContextDescription={coverageDescription}
            participantFirstName={participant?.get('first_name')}
            participantLastName={participant?.get('last_name')}
            participantBirthDate={participant?.get('date_of_birth')}
            participantState={participantAddress?.get('state')}
            participantStreetAddress={participantAddress?.streetAddress}
            customerFirstName={customer?.get('first_name')}
            customerLastName={customer?.get('last_name')}
            customerEmailAddress={customer?.get('email')}
            customerState={customerAddress?.get('state')}
            customerPostalCode={customerAddress?.get('postal_code')}
            customerCity={customerAddress?.get('city')}
            customerStreetAddress={customerAddress?.streetAddress}
          />
        );
      })}
    </>
  );
}

function Insurance({
  clearOnInit = true,
  cart,
  clientDataStore,
  eventDataStore,
  sessionDataStore,
  onInsuranceChange = () => {},
  onClearInsurance = () => {},
  onEmptyOfferError = () => {},
}) {
  useEffect(() => {
    const insuranceAmount = cart.get('insurance_amount', 0);

    if (insuranceAmount > 0 && clearOnInit) {
      onClearInsurance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearOnInit, onClearInsurance]);

  const insuranceEnabled = enabledCustomerFeatures(['insurance']);

  if (!insuranceEnabled) return null;

  const { clients } = clientDataStore;
  const { events } = eventDataStore;
  const { sessions } = sessionDataStore;
  const orderItems = cart.get('order_items');
  const insuranceItems = orderItems.filter(
    oi => oi.isEventItem() && (oi.isTeamItem() || oi.isFixedScheduleItem())
  );

  if (insuranceItems.size === 0) return null;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <MultiOfferWrapperElementComponent
        hideOnError
        className="insurance-gap-medical"
        product="gap-medical"
        clientId={INSURANCE_CLIENT_ID}
        offerStateChange={e =>
          onInsuranceChange({ type: 'gap-medical', data: e.detail })
        }
        emptyOfferError={e => onEmptyOfferError(e, 'gap-medical')}
      >
        {insuranceItems.map((item, index) => (
          <InsuranceItem
            // eslint-disable-next-line react/no-array-index-key
            key={`gap-medical-${index}`}
            orderable={item.orderable}
            total={item.total}
            clients={clients}
            events={events}
            sessions={sessions}
            type="gap-medical"
          />
        ))}
      </MultiOfferWrapperElementComponent>
      <MultiOfferWrapperElementComponent
        hideOnError
        className="insurance-registration-cancellation"
        product="registration-cancellation"
        clientId={INSURANCE_CLIENT_ID}
        offerStateChange={e =>
          onInsuranceChange({
            type: 'registration-cancellation',
            data: e.detail,
          })
        }
        emptyOfferError={e => onEmptyOfferError(e, 'registration-cancellation')}
      >
        {insuranceItems.map((item, index) => (
          <InsuranceItem
            // eslint-disable-next-line react/no-array-index-key
            key={`registration-cancellation-${index}`}
            orderable={item.orderable}
            total={item.total}
            clients={clients}
            events={events}
            sessions={sessions}
            type="registration-cancellation"
          />
        ))}
      </MultiOfferWrapperElementComponent>
    </div>
  );
}

Insurance.propTypes = {
  clearOnInit: PropTypes.bool,
  cart: PropTypes.object.isRequired,
  clientDataStore: PropTypes.object.isRequired,
  eventDataStore: PropTypes.object.isRequired,
  sessionDataStore: PropTypes.object.isRequired,
  onInsuranceChange: PropTypes.func,
  onClearInsurance: PropTypes.func,
  onEmptyOfferError: PropTypes.func,
};

export default compose(
  memo,
  altContainer({
    stores: {
      clientDataStore: ClientDataStore,
      eventDataStore: EventDataStore,
      sessionDataStore: SessionDataStore,
    },
  })
)(Insurance);
