/* eslint-disable react/require-default-props */
import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  Drawer,
  TextField,
  Grid,
  Button,
  Typography,
} from '@upperhand/playmaker';

import { currentCustomer } from 'shared/utils/CustomerUtils';
import { t } from 'shared/utils/LocaleUtils';

import CustomBusinessEmail from 'shared/records/CustomBusinessEmail';

import { CustomBusinessEmailsSource } from 'sources';

function ClientEmailDrawerEditor({
  open = false,
  isLoading = false,
  title = '',
  emailTemplateId = null,
  onClose = () => {},
  onCreate = () => {},
  onUpdate = () => {},
}) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState(new CustomBusinessEmail());

  useEffect(() => {
    if (emailTemplateId) {
      setLoading(true);
      CustomBusinessEmailsSource.fetch({
        id: currentCustomer().id,
        emailId: emailTemplateId,
        success: data => {
          setTemplate(data);
          setLoading(false);
        },
        error: () => {
          setLoading(false);
        },
      });
    }
  }, [emailTemplateId]);

  useEffect(() => {
    if (!open) {
      setTemplate(new CustomBusinessEmail());
    }
  }, [open]);

  const handleClose = () => {
    setTemplate(new CustomBusinessEmail());
    onClose();
  };

  return (
    <Drawer
      classes={{
        root: 'client-emails-drawer-editor',
        footer: 'client-emails-drawer-editor__footer',
        header: 'client-emails-drawer-editor__header',
        actions: 'client-emails-drawer-editor__actions',
        content: 'client-emails-drawer-editor__content',
      }}
      headerType="dark"
      open={open}
      onClose={handleClose}
      title={title}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {t('.info', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={loading || isLoading}
              label={t('.message_title', intl, __filenamespace)}
              placeholder={t(
                '.message_title_placeholder',
                intl,
                __filenamespace
              )}
              value={template?.subject}
              onChange={e =>
                setTemplate(template.set('subject', e.target.value))
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={10}
              label={t('.message_body', intl, __filenamespace)}
              placeholder={t(
                '.message_body_placeholder',
                intl,
                __filenamespace
              )}
              disabled={loading || isLoading}
              value={template?.body}
              onChange={e => setTemplate(template.set('body', e.target.value))}
            />
          </Grid>
          <div className="client-emails-drawer-editor__footer-actions">
            <Button type="tertiary" onClick={handleClose}>
              {t('.cancel', intl, __filenamespace)}
            </Button>

            <Button
              disabled={!template?.body || isLoading}
              onClick={() =>
                template?.id ? onUpdate(template) : onCreate(template)
              }
            >
              {template?.id
                ? t('.update', intl, __filenamespace)
                : t('.save', intl, __filenamespace)}
            </Button>
          </div>
        </Grid>
      }
      footer={<div className="client-emails-drawer-editor__footer" />}
    />
  );
}

ClientEmailDrawerEditor.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  emailTemplateId: PropTypes.string,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default memo(ClientEmailDrawerEditor);
