import { Map, Record, Set } from 'immutable';

import TeamType from 'records/TeamType.jsx';
import Image from 'records/Image'; // Important to use direct import for resolving import/no-cycle issue
import { currentUser } from 'shared/utils/UserUtils.jsx';
import TeamDetail from 'team_mgmt/shared/records/TeamDetail.jsx';

const DEFAULT_DESCRIPTION =
  'More event information to follow. Let us know if you have any questions in the meantime.';

const constructionModifierForImmutableObject = object => {
  object.set('image', new Image(object.get('image')));
  object.set('team_detail', new TeamDetail(object.get('team_detail')));
  object.set('team_type', new TeamType(object.get('team_type')));
  object.set('seasons', Set(object.get('seasons', [])));
  object.update('participant_ids', (ids = []) => Set(ids));
  object.update('registered_client_ids', (ids = []) => Set(ids));
};

const constructionModifierForPlainObject = object =>
  Map(object).withMutations(constructionModifierForImmutableObject);

class CustomerTeam extends Record({
  id: null,
  uuid: null,
  admin_path: '',
  base_price: '',
  description: '',
  sport_type: '',
  team_type: new TeamType(),
  image: new Image(),
  image_dimensions: {},
  path: '',
  privacy: false,
  payment_methods: Map(),
  status: 'active',
  title: '',
  url: '',
  team_detail: new TeamDetail(),
  freeEvent: false,
  price: '',
  team_type_id: null,
  participant_ids: Set(),
  registered_client_ids: Set(),
  seasons: Set(),
}) {
  constructor(obj = {}, options = {}) {
    let objRef;

    if (obj?.asImmutable) {
      objRef = obj.withMutations(constructionModifierForImmutableObject);
    } else {
      objRef = constructionModifierForPlainObject(obj);
    }

    super(objRef, options);
  }

  get dateRange() {
    if (this.seasons.length === 0) {
      return {};
    }

    const seasonDates = (season, year) => {
      const leapYear = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
      const dates = {
        Winter: [
          new Date(year - 1, 11, 1),
          new Date(year, 1, leapYear ? 29 : 28),
        ],
        Spring: [new Date(year, 2, 1), new Date(year, 4, 31)],
        Summer: [new Date(year, 5, 1), new Date(year, 7, 31)],
        Fall: [new Date(year, 8, 1), new Date(year, 10, 30)],
      };
      return dates[season];
    };

    return this.seasons.reduce((result, seasonYear) => {
      const [season, yearStr] = seasonYear.split(' ');
      const year = parseInt(yearStr, 10);
      const seasonRange = seasonDates(season, year);

      if (!seasonRange) {
        return result;
      }

      const [seasonStart, seasonEnd] = seasonRange;

      if (Object.keys(result).length === 0) {
        return { startDate: seasonStart, endDate: seasonEnd };
      }

      return {
        startDate: new Date(Math.min(result.startDate, seasonStart)),
        endDate: new Date(Math.max(result.endDate, seasonEnd)),
      };
    }, {});
  }

  editUrl() {
    return `${this.path}/edit`;
  }

  getHtmlDescription() {
    return this.description.toString('html');
  }

  getRichDescription() {
    if (!this.description) {
      return DEFAULT_DESCRIPTION;
    }

    return this.description;
  }

  isCancelled() {
    return this.status === 'cancelled';
  }

  // eslint-disable-next-line class-methods-use-this
  isTeamEvent() {
    return true;
  }

  typeColor() {
    return this?.team_type?.color ?? 'white';
  }

  getTeamLink() {
    return currentUser().isClient() ? this.path : this.admin_path;
  }

  isAlreadyRegistered(userId) {
    return (
      this.participant_ids.has(userId) || this.registered_client_ids.has(userId)
    );
  }

  async toServer() {
    const data = this.toJS();
    if (data.image && !data.image.file) {
      delete data.image;
    } else {
      data.image = await this.image.toServer();
    }
    delete data.team_type;
    delete data.participant_ids;
    delete data.registered_client_ids;
    return data;
  }

  toJS() {
    if (this.description && !(typeof this.description === 'string')) {
      return this.set('description', this.description.toString('html')).toJS();
    }
    return super.toJS();
  }

  url() {
    if (currentUser().isStaff()) {
      return this.admin_path;
    }
    return this.path;
  }
}

export default CustomerTeam;
