import { List } from 'immutable';

import CustomBusinessEmail from 'shared/records/CustomBusinessEmail';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onSuccess } from 'sources/handlers';

import DataStoreActions from './CreditPassActions';

const parserTo = {
  type: CustomBusinessEmail,
  paginationKey: 'custom_business_emails',
};

const parser = new Parser(parserTo);

export const fetch = ({ id, emailId, success, error }) => {
  uhApiClient.get({
    url: `customers/${id}/${parserTo.paginationKey}/${emailId}`,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error,
  });
};

export const create = ({ id, body, success, error }) => {
  uhApiClient.post({
    url: `customers/${id}/${parserTo.paginationKey}`,
    data: JSON.stringify(body),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.createSuccess, success])
    ),
    error,
  });
};

export const update = ({ id, emailId, body, success, error }) => {
  uhApiClient.put({
    url: `customers/${id}/${parserTo.paginationKey}/${emailId}`,
    data: JSON.stringify(body),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.updateSuccess, success])
    ),
    error,
  });
};

export const remove = ({ id, emailId, success, error }) => {
  uhApiClient.delete({
    url: `customers/${id}/${parserTo.paginationKey}/${emailId}`,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.deleteSuccess, success])
    ),
    error,
  });
};
