import * as React from 'react';
import clsx from 'clsx';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  TextField,
  Checkbox,
  Grid,
  Spinner,
  Typography,
} from '@upperhand/playmaker';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { TextField as MUITextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import WysiwygEditor from 'shared/components/WysiwygEditor.jsx';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';

import MessagingDrawerActions from 'shared/actions/MessagingDrawerActions.js';
import MessagingDrawerStore, {
  STEPS,
} from 'shared/stores/MessagingDrawerStore.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { enabledCustomerFeatures } from 'shared/utils/CustomerUtils';
import { FlexBox, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { STANDARD_DRAWER_WIDTH, smallScreen } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhContrast } from 'shared/styles/uhStyles.jsx';

const styles = {
  contentContainer: {
    fontSize: 15,
    padding: '15px 30px',
    height: '100%',
  },

  groupName: {
    fontSize: 22,
  },

  header: merge(uhContrast.lightOnDark, {
    alignItems: 'center',
    fontSize: 15,
    paddingLeft: 30,
    minHeight: 56,
  }),

  label: {
    fontSize: 18,
  },

  zIndex: {
    zIndex: 1301,
  },

  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },

  actionBtn: {
    backgroundColor: 'var(--color-white)',
    color: 'var(--color-primary)',
    height: '30px',
    width: '130px',

    '&:hover': {
      backgroundColor: 'var(--color-white)',
    },
  },
};

const drawerClosed = () => MessagingDrawerActions.drawerClosed();
const onCancelClicked = () => MessagingDrawerActions.cancelClicked();
const onSendClicked = () => MessagingDrawerActions.sendClicked();
const onMessageUpdated = value => MessagingDrawerActions.messageUpdated(value);
const onSubjectUpdated = (_, value) =>
  MessagingDrawerActions.subjectUpdated(value);

const StyledPreview = styled(Box)(() => ({
  padding: '10px',
  margin: '20px 15px',
  border: '1px solid var(--color-ui-gray)',
  borderRadius: '5px',

  table: {
    width: '100% !important',
  },

  '*': {
    width: 'initial !important',
    backgroundColor: 'var(--color-white)',
  },

  '#header-card > table > tbody > tr:nth-child(5) > td:nth-child(2) > div': {
    width: '100% !important',
  },
}));

const Header = injectIntl(({ intl, emailTemplate, step, title }) => (
  <FlexBoxJustify style={styles.header}>
    {title || (
      <FormattedMessage id={messageId('.create_message', __filenamespace)} />
    )}
    <Stack direction="row" spacing={1} alignItems="center">
      {emailTemplate && step === STEPS.messaging && (
        <Button
          variant="contained"
          sx={styles.actionBtn}
          onClick={() => MessagingDrawerActions.changeStep(STEPS.preview)}
        >
          {t('.preview', intl, __filenamespace)}
        </Button>
      )}
      {emailTemplate && step === STEPS.preview && (
        <Button
          variant="contained"
          sx={styles.actionBtn}
          onClick={() => MessagingDrawerActions.changeStep(STEPS.messaging)}
        >
          {t('.return_to_edit', intl, __filenamespace)}
        </Button>
      )}
      <IconButton onClick={drawerClosed}>
        <CloseIcon sx={{ color: 'white' }} />
      </IconButton>
    </Stack>
  </FlexBoxJustify>
));

function PreviewTemplate({ message, step, emailTemplate }) {
  React.useEffect(() => {
    const preview = document.querySelector('.email-preview');

    if (preview && step === STEPS.preview && emailTemplate) {
      const messageBody = preview.querySelector('.message_body');
      const messageSubject = preview.querySelector('.message_subject');

      messageBody.innerHTML = message.body;
      messageSubject.innerHTML = message.subject;
    }
  }, [message, step, emailTemplate]);

  return (
    <StyledPreview
      className={clsx('email-preview', 'rich-text-container')}
      dangerouslySetInnerHTML={{ __html: emailTemplate }}
    />
  );
}

const MessagingDrawer = injectIntl(({ messagingDrawerStore, title, intl }) => {
  const [openDatePicker, setOpenDatePicker] = React.useState(false);
  const [openTimePicker, setOpenTimePicker] = React.useState(false);
  const {
    message,
    open,
    processing,
    valid,
    step,
    emailTemplate,
    templateLoading,
  } = messagingDrawerStore;

  const drawerWidth = emailTemplate ? 750 : STANDARD_DRAWER_WIDTH;
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return (
    <Drawer
      disableEnforceFocus
      open={open}
      anchor="right"
      PaperProps={{
        sx: {
          width: smallScreen() ? 0.9 * window.innerWidth : drawerWidth,
        },
      }}
      sx={{ zIndex: 1301 }}
      onClose={shouldOpen => !shouldOpen && drawerClosed}
    >
      <Header step={step} title={title} emailTemplate={emailTemplate} />
      {templateLoading && (
        <div style={styles.spinner}>
          <Spinner />
        </div>
      )}
      {step === STEPS.preview && (
        <PreviewTemplate
          step={step}
          message={message}
          emailTemplate={emailTemplate}
        />
      )}
      {step === STEPS.messaging && !templateLoading && (
        <div style={styles.contentContainer}>
          <Grid container direction="column" spacing={3}>
            <Grid item xs>
              <TextField
                fullWidth
                label={t('.subject', intl, __filenamespace)}
                placeholder={t('.subject', intl, __filenamespace)}
                onChange={onSubjectUpdated}
                value={message.subject}
              />
            </Grid>
            <Grid item xs>
              {emailTemplate ? (
                <WysiwygEditor
                  useBase64={false}
                  value={message.body}
                  height={window.innerHeight - 300}
                  onChange={onMessageUpdated}
                />
              ) : (
                <>
                  <TextField
                    fullWidth
                    label={t('.message', intl, __filenamespace)}
                    placeholder={t('.type_a_message', intl, __filenamespace)}
                    multiline
                    onChange={(_, value) => onMessageUpdated(value)}
                    value={message.body}
                  />
                  {message.smsEnabled && (
                    <Typography>
                      {t('.send_as_text_notice', intl, __filenamespace, {
                        length: message.body.length,
                      })}
                    </Typography>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs container direction="column">
              {!emailTemplate && enabledCustomerFeatures(['sms']) && (
                <Checkbox
                  label={t('.send_as_text', intl, __filenamespace)}
                  onChange={(_, isChecked) =>
                    MessagingDrawerActions.smsEnabledUpdated(isChecked)
                  }
                  checked={message.smsEnabled}
                />
              )}
              <Checkbox
                label={t('.send_later', intl, __filenamespace)}
                onChange={(_, isChecked) =>
                  MessagingDrawerActions.sendLaterUpdated(isChecked)
                }
                checked={message.sendLater}
              />
            </Grid>
          </Grid>

          {message.sendLater && (
            <Stack spacing={1} direction="row" style={{ marginBottom: 15 }}>
              <DatePicker
                label={
                  !message.sendDate
                    ? t('.choose_date', intl, __filenamespace)
                    : ''
                }
                value={message.sendDate}
                open={openDatePicker}
                onOpen={() => setOpenDatePicker(true)}
                onClose={() => setOpenDatePicker(false)}
                renderInput={params => (
                  <MUITextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        ...params.inputProps.style,
                        cursor: 'pointer',
                        caretColor: 'transparent',
                      },
                    }}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                      shrink: true,
                    }}
                    onClick={() => setOpenDatePicker(true)}
                    onKeyDown={e => e.preventDefault()}
                  />
                )}
                shouldDisableDate={date => date < today}
                onChange={value => {
                  MessagingDrawerActions.sendDateUpdated(
                    value ? value.toDate() : undefined
                  );
                }}
                DialogProps={{ disableEnforceFocus: true, sx: styles.zIndex }}
                PopperProps={{ sx: styles.zIndex }}
              />
              <TimePicker
                label={
                  !message.sendTime
                    ? t('.choose_time', intl, __filenamespace)
                    : ''
                }
                value={message.sendTime}
                open={openTimePicker}
                onOpen={() => setOpenTimePicker(true)}
                onClose={() => setOpenTimePicker(false)}
                renderInput={params => (
                  <MUITextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        ...params.inputProps.style,
                        cursor: 'pointer',
                        caretColor: 'transparent',
                      },
                    }}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                      shrink: true,
                    }}
                    onClick={() => setOpenTimePicker(true)}
                    onKeyDown={e => e.preventDefault()}
                  />
                )}
                onChange={time =>
                  MessagingDrawerActions.sendTimeUpdated(
                    time ? time.toDate() : undefined
                  )
                }
                DialogProps={{ disableEnforceFocus: true, sx: styles.zIndex }}
                PopperProps={{ sx: styles.zIndex }}
              />
            </Stack>
          )}

          <FlexBox style={{ justifyContent: 'flex-end' }}>
            <Button disabled={processing} onClick={onCancelClicked}>
              {t('actions.cancel', intl)}
            </Button>
            <StateChangingButton
              disabled={!valid}
              inProgress={processing}
              label={t('.send_email', intl, __filenamespace)}
              labelInProgress={t('.sending', intl, __filenamespace)}
              onClick={onSendClicked}
              style={{ marginLeft: 8 }}
            />
          </FlexBox>
        </div>
      )}
    </Drawer>
  );
});

export default altContainer({
  stores: {
    messagingDrawerStore: MessagingDrawerStore,
  },
})(MessagingDrawer);
